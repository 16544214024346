export const seller = [
  {
    title: 'Free',
    price: 0,
    priceId: 'free',
    description: '1 Post 5 Images',
    posts: 3,
    images: 5,
  },
  {
    title: 'Broze',
    price: 5,
    priceId: 'sellerBroze',
    description: 'Buy 1 Post 15 Images',
    posts: 1,
    images: 15,
  },
  {
    title: 'Platinum',
    price: 15,
    priceId: 'sellerPlatinum',
    description: 'Buy 5 Post',
    posts: 5,
    images: 15,
  },
  {
    title: 'Gold',
    price: 25,
    priceId: 'sellerGold',
    description: 'Buy 10 Post',
    posts: 10,
    images: 15,
  },
];

export const dealer = {
  month: [
    {
      title: 'Free',
      price: 0,
      priceId: 'free',
      description: '10 Post 5 Images',
      posts: 10,
      images: 5,
    },
    {
      title: 'Broze',
      price: 50,
      priceId: 'dealerBroze',
      description: 'Buy 50 Post',
      posts: 50,
      images: 15,
    },
    {
      title: 'Platinum',
      price: 75,
      priceId: 'dealerPlatinum',
      description: 'Buy 75 Post',
      posts: 75,
      images: 15,
    },
    {
      title: 'Gold',
      price: 100,
      priceId: 'dealerGold',
      description: 'Buy 150 Post',
      posts: 150,
      images: 15,
    },
  ],
  year: [
    {
      title: 'Broze Pro',
      price: 500,
      priceId: 'dealerBrozePro',
      description: 'Full pay yearly',
      posts: 500,
      images: 15,
    },

    {
      title: 'Platinum Pro',
      price: 700,
      priceId: 'dealerPlatinumPro',
      description: 'Full pay yearly',
      posts: 1000,
      images: 15,
    },
    {
      title: 'Gold Pro',
      price: 900,
      priceId: 'dealerGoldPro',
      description: 'Full pay yearly',
      posts: 1000,
      images: 15,
    },
  ],
};

export const featuredPackages = [
  {
    title: 'Silver',
    price: 5,
    priceId: 'postFeatureSilver',
    description: '10 days $5',
    days: 10,
  },
  {
    title: 'Gold',
    price: 8,
    priceId: 'postFeatureGold',
    description: '20 days $8',
    days: 20,
  },
];
