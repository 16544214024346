/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import raf from 'rc-util/lib/raf';
import * as React from 'react';
import { Info } from 'react-feather';
import { Controller } from 'react-hook-form';

const HandleTooltip = (props) => {
  const {
    value,
    children,
    visible,
    tipFormatter = (val) => (val === children.props['aria-valuemax'] ? 'any' : `${val} m`),
    ...restProps
  } = props;

  const tooltipRef = React.useRef();
  const rafRef = React.useRef(null);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forceAlign();
    });
  }

  React.useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: 'auto' }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

const TooltipSlider = ({
  tipFormatter,
  tipProps,
  label,
  errorText,
  ...props
}) => {
  const tipHandleRender = (node, handleProps) => (
    <HandleTooltip
      value={handleProps.value}
      visible={handleProps.dragging}
      tipFormatter={tipFormatter}
      {...tipProps}
    >
      {node}
    </HandleTooltip>
  );

  return (
    <div className="w-full">
      {label && (
        <div className="flex justify-between mb-1 ml-1">
          <label className="block text-lg font-medium text-white">
            {label}
          </label>
        </div>
      )}
      <div className="px-2">
        <Controller
          name={props.name}
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Slider
              {...props}
              handleRender={tipHandleRender}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </div>

      {!!errorText && (
        <div className="flex items-center space-x-1 mt-2">
          <Info color="#dc2626" size={20} />
          <p className="text- font-medium  py-white text-red-500">
            {errorText}
          </p>
        </div>
      )}
    </div>
  );
};

export default TooltipSlider;
