import React, { Fragment } from 'react';
import { Menu as MenuIcon, ArrowRight } from 'react-feather';
import { Menu, Transition } from '@headlessui/react';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import categoryData from '../../../static/categories-data';
import { classNames } from '../../../util/functions';

/* =============================================================================
<HomeHeader />
============================================================================= */
const HomeHeader = () => {
  const history = useHistory();

  const _handlePress = (categoryId) => {
    history.push(`/category/${categoryId}`);
  };

  return (
    <>
      <div className="flex-1 flex w-auto overflow-x-auto py-2 xsm:px-px md:py-0 scrollbar-hide shrink items-center space-x-2 relative right-3 xsm:right-0 lg:right-[6px] ">
        {categoryData?.map((category) => (
          <div className="flex xsm:flex-col xsm:w-[59px] space-x-2">
            <button
              key={category?.id}
              type="button"
              className="flex items-center px-3 sm:mt-1  xsm:bg-gradient-to-b from-gradiantColor1 to-gradiantColor4 xsm:h-14 xsm:w-14 justify-center  space-x-3 border border-r border-transparent  py-1 xsm:pt-0 hover:bg-lightWhite rounded-full shadow-sm"
              onClick={() => _handlePress(category?.id)}
            >
              <div className="flex justify-center">{category?.icon}</div>
              <h1 className="text-white text-sm whitespace-nowrap xsm:hidden">
                {category?.title}
              </h1>
            </button>
            <h1 className="hidden text-white text-center text-sm max-w-full truncate xsm:block ">
              {category?.title}
            </h1>
          </div>
        ))}

        <button
          type="button"
          className={classNames(
            'flex items-center px-3 mt-1 border border-transparent space-x-2 py-1 hover:bg-lightWhite rounded-full',
          )}
          onClick={() => history.push('/settings/blog')}
        >
          <h1 className={classNames('text-white text-sm whitespace-nowrap')}>
            Blog
          </h1>
        </button>
        <button
          type="button"
          className={classNames(
            ' items-center px-3 mt-1  space-x-2 py-1 hover:bg-lightWhite rounded-full border-2 border-blue-500 hidden xsm:block',
          )}
          onClick={() => history.push('/stories')}
        >
          <h1 className={classNames('text-white text-sm whitespace-nowrap ')}>
            Stories
          </h1>
        </button>
      </div>
    </>
  );
};

/* Export
============================================================================= */
export default HomeHeader;
