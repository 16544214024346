import {
  collection,
  onSnapshot,
  query,
  deleteDoc,
  doc,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { auth, firestore } from '../../config/firebase';
import { SideSheet } from '../../common/Sidesheet';
import { Table } from '../../common/Table/Table';
import request from '../../util/request';
import withAuth from '../../util/withAuth';

const Admins = () => {
  const columns = [
    {
      key: 'name',
      title: 'Name',
      dbColName: 'name',
    },
    {
      key: 'email',
      title: 'Email',
      dbColName: 'email',
    },
    {
      key: 'phone',
      title: 'Phone',
      dbColName: 'phone',
    },

    {
      key: 'actions',
      title: 'Actions',
      render: (values) => (
        <button type="button" onClick={() => handleDelete(values.id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
      ),
    },
  ];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    setLoading(true);
    // Create a query against the collection
    const q = query(collection(firestore, 'users'));
    // Real-time updates listener
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const usersArray = [];

      for (const d of querySnapshot.docs) {
        if (d.data().type === 'Admin') {
          const inventory = {
            id: d.id,
            ...d.data(),
          };
          usersArray.push(inventory);
        }
      }

      setData(usersArray);
      setLoading(false);
    });

    // Detach listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleSubmit = async () => {
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        state.email,
        state.password,
      );
      await setDoc(
        doc(firestore, 'users', res.user.uid),
        {
          id: res.user.uid,
          name: state.name,
          type: 'Admin',
          business: {},
          postPackage: {
            packageId: 'free',
            numberOfPosts: 3,
          },
          phone: state.phone,
          favoritePosts: [],
          followers: [],
          email: state.email,
          createdAt: serverTimestamp(),
        },
        {
          settings: {
            notifications: {},
          },
        },
      );
      setIsOpen(false);
      setState({
        name: '',
        email: '',
        password: '',
        phone: '',
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete the list?')) {
      request({
        url: `/admin/deleteUserData?id=${id}`,
        method: 'DELETE',
      })
        .then(() => {
          const shallowCopy = [...data];
          const index = shallowCopy.findIndex(
            (d) => d.id === id || d.uid === id,
          );
          shallowCopy.splice(index, 1);
          setData(shallowCopy);
        })
        .catch((error) => {
          console.log(error);
        });
      alert('Admin deleted successfully');
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-4">
        <div className="flex items-center justify-between">
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Admins List
          </h1>
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="bg-primary px-3 py-1.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 "
          >
            New Admin
          </button>
        </div>
        <p className="text-gray-500 text-sm">View all admin</p>
      </div>
      <SideSheet
        sidebarOpen={isOpen}
        setSidebarOpen={setIsOpen}
        handleClickPrimary={handleSubmit}
        title="Add New Admin"
      >
        <form className="flex flex-col gap-3.5">
          <div className="flex flex-col gap-1.5">
            <label htmlFor="name" className="text-zinc-800">
              Name
              <input
                type="text"
                value={state.name}
                name="name"
                onChange={handleChange}
                placeholder="Enter user name"
                className="rounded-md border border-zinc-200 px-3 mt-1 py-2 w-full shadow-sm"
              />
            </label>
          </div>
          <div className="flex flex-col gap-1.5">
            <label htmlFor="email" className="text-zinc-800">
              Email
              <input
                type="email"
                name="email"
                value={state.email}
                onChange={handleChange}
                placeholder="Enter user email"
                className="rounded-md border border-zinc-200 px-3 mt-1 py-2 w-full shadow-sm"
              />
            </label>
          </div>
          <div className="flex flex-col gap-1.5">
            <label htmlFor="phone" className="text-zinc-800">
              Phone Number
              <input
                type="text"
                name="phone"
                value={state.phone}
                onChange={handleChange}
                placeholder="Enter phone number"
                className="rounded-md border border-zinc-200 px-3 mt-1 py-2 w-full shadow-sm"
              />
            </label>
          </div>
          <div className="flex flex-col gap-1.5">
            <label htmlFor="password" className="text-zinc-800">
              Password
              <input
                type="password"
                name="password"
                value={state.password}
                onChange={handleChange}
                placeholder="Enter password"
                className="rounded-md border border-zinc-200 px-3 mt-1 py-2 w-full shadow-sm"
              />
            </label>
          </div>
        </form>
      </SideSheet>
      <Table
        data={data}
        columns={columns}
        loadingData={loading}
        totalItems={data?.length}
      />
    </div>
  );
};

export default withAuth(Admins);
