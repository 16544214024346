/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import '../config/carouselStyles.css';
import FsLightbox from 'fslightbox-react';
import { classNames } from '../util/functions';
import useWindowDimensions from '../hooks/useWindowDimensions';

/* =============================================================================
<PhotosCarousel />
============================================================================= */
const PhotosCarousel = ({
  photos,
  children,
  customStyles,
  imgStyles,
  postId,
}) => {
  const { width } = useWindowDimensions();
  const [toggler, setToggler] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (postId) {
      setCurrentIndex(0);
    }
  }, [postId]);
  return (
    <div
      className={classNames('h-auto max-h-1/2 md:w-2/3 relative', customStyles)}
    >
      <div className="bg-black bg-opacity-75  absolute top-3 left-2 z-10 rounded-lg py-1 px-4">
        <p className="text-white text-sm">
          {`${currentIndex + 1} of ${photos?.length}`}
        </p>
      </div>
      <Carousel
        key={postId}
        swipeable
        dynamicHeight
        showArrows={false}
        className="relative"
        showThumbs={width > 480}
        renderThumbs={() => photos.map((item) => (
          <img
            src={item}
            className="w-auto h-10 border-none"
            alt={`thumnail ${item}`}
          />
        ))}
        onChange={(index) => setCurrentIndex(index)}
      >
        {photos.map((item, index) => (
          <button
            type="button"
            key={`slide${index + 1}`}
            onClick={() => setToggler(!toggler)}
            className="w-full overflow-hidden xsm:aspect-[5/4]"
            style={{ height: width > 480 && 500 }}
          >
            <img
              src={item}
              className={classNames(
                'w-full h-full object-cover rounded-2xl xsm:rounded-none',
                imgStyles,
              )}
              alt={item}
            />
          </button>
        ))}
      </Carousel>
      {!!children && children}
      <FsLightbox
        toggler={toggler}
        sourceIndex={currentIndex}
        type="image"
        sources={photos}
      />
      <div />
    </div>
  );
};

PhotosCarousel.defaultProps = {
  photos: [],
};
const propsAreEqual = (prev, next) => prev?.postId === next?.postId
  && prev?.photos?.length === next?.photos?.length;

// export default React.memo(PhotosCarousel, propsAreEqual);
export default React.memo(PhotosCarousel);
