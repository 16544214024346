import React, { useEffect, useState } from 'react';
import request from '../util/request';
import { Table } from '../common/Table/Table';
import withAuth from '../util/withAuth';

const Dashboard = () => {
  const columns = [
    {
      key: 'name',
      title: 'Name',
      dbColName: 'name',
    },
    {
      key: 'email',
      title: 'Email',
      dbColName: 'email',
    },
    {
      key: 'phone',
      title: 'Phone',
      dbColName: 'phone',
    },
    {
      key: 'location',
      title: 'Location',
      dbColName: 'location',
      render: (row) => <span>{row?.formattedAddress}</span>,
    },
    {
      key: 'packdage',
      title: 'Posts',
      dbColName: 'postPackage',
      render: (row) => <span>{row?.numberOfPosts}</span>,
    },
    {
      key: 'packdage',
      title: 'Package',
      dbColName: 'postPackage',
      render: (row) => <span>{row?.packageId}</span>,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (values) => (
        <button type="button" onClick={() => handleDelete(values.uid)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Create a query against the collection
    request({
      url: '/admin/getUsersData',
      method: 'GET',
    })
      .then((resp) => {
        setData(resp.mergedUsers);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete the user?')) {
      request({
        url: `/admin/deleteUserData?id=${id}`,
        method: 'DELETE',
      })
        .then((resp) => {
          const shallowCopy = [...data];
          const index = shallowCopy.findIndex(
            (d) => d.id === id || d.uid === id,
          );
          shallowCopy.splice(index, 1);
          setData(shallowCopy);
        })
        .catch((error) => {
          console.log(error);
        });
      alert('User deleted successfully');
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Users
        </h1>
        <p className="text-gray-500 text-sm">View all users</p>
      </div>
      <Table
        data={data}
        columns={columns}
        loadingData={loading}
        totalItems={data.length}
      />
    </div>
  );
};

export default withAuth(Dashboard);
